<template>
  <div class="">
    <header id="site-header" class="fixed-top">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light stroke py-lg-0">
                <h1><router-link class="navbar-brand pe-xl-5 pe-lg-4" to="/">
                        <img src="@/assets/images/logo.png" alt="" style="width:70px;height70px">
                        <span> 帆笙信息科技 </span>
                    </router-link>
                </h1>
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <ul class="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li class="nav-item">
                            <router-link class="nav-link " to="/about">关于我们</router-link>
                        </li>
                        <li class="nav-item pro-view products">
                            <a class="nav-link ">产品</a>
                            <div class="view1">
                              <p><router-link class="pro_links" to="/productYuan">智慧小微园</router-link></p>
                              <p><router-link class="pro_links" to="/productNao">产业大脑</router-link></p>
                              <p><router-link class="pro_links" to="/product">商城小程序</router-link></p>
                              <p><router-link class="pro_links" to="/productSan">三维建模</router-link></p>
                            </div>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link active" to="/">首页</router-link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    <section class="w3l-main-slider banner-slider position-relative" id="home">
        <div class="owl-one owl-carousel owl-theme">
            <div class="item">
                <div class="slider-info banner-view banner-top1" style="min-height:400px">
                    <div class="container">
                        <div class="banner-info header-hero-19">
                            <h3 class="title-hero-19">科技创造未来</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="w3l-3-grids py-5" id="grids-3">
        <div class="container py-md-5 py-2">
            <div class="row mb-5 pb-lg-4">
                <div class="col-lg-4 pe-lg-4">
                    <div class="title-content text-left">
                        <h6 class="title-subw3hny mid-size">&nbsp;</h6>
                        <h3 class="title-w3l">致力于一流的IT解决方案与服务供应商</h3>
                    </div>
                </div>
                <div class="col-lg-4 mt-lg-0 mt-md-5 mt-4 ps-lg-4">
                    <p class="">杭州帆笙信息科技有限公司，于2019年成立于美丽的天堂硅谷—杭州，
                        注册资金1000万,是一家专业为政府及企事业单位提供互联网+整体解决方案的软件和服务供应商。</p>
                </div>
                <div class="col-lg-4 mt-lg-0 mt-md-5 mt-4 ps-lg-4">
                    <p class="">公司自成立以来，致力于移动互联网、大数据技术整合的研发，并与国内外知名专家、
                        学者及机构进行资源合作，为客户提供技术领先的软件产品和优质的解决方案。主营通信及互联网行业的软件服务，
                        以“诚信、敬业、和谐、共赢”为企业核心价值观，希望通过我们的专业水平和不懈努力，为更多企业客户提供技术服务。</p>
                </div>
            </div>
            <router-link class="knowmore" to="/about">
              <span>了解更多 </span>
              <i class="fas fa-angle-double-right"></i>
            </router-link>
            <div class="row pt-md-0 pt-5">
                <router-link :to="{name:'about',params:{level:1}}" class=" col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
                    <div class="grids3-info position-relative">
                        <a href="#" class="d-block zoom"><img src="../assets/images/companyaddress.png" alt="" class="img-fluid news-image part1-imgs"></a>
                        <div class="w3-grids3-info light">
                            <h6><a href="#category" class="category d-block">01</a></h6>
                            <h4 class="gdnhy-1"><a href="#">公司地址</a>
                                <a class="w3item-link" href="#">
                                    <i class="fas fa-arrow-right ms-lg-4"></i>
                                </a>
                            </h4>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'about',params:{level:2}}" class="col-lg-4 col-md-6 mt-md-0 mt-4 px-lg-0 grids3-info2">
                    <div class="grids3-info second position-relative">
                        <a href="#" class="d-block zoom"><img src="../assets/images/companyteam.png" alt="" class="img-fluid news-image part1-imgs"></a>
                        <div class="w3-grids3-info second light">
                            <h6><a href="#category" class="category d-block">02</a></h6>
                            <h4 class="gdnhy-1"><a href="#">项目管理</a>
                                <a class="w3item-link" href="#">
                                    <i class="fas fa-arrow-right ms-lg-4"></i>
                                </a>
                            </h4>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'about',params:{level:3}}" class="col-lg-4 col-md-6 mt-lg-0 mt-4 px-lg-0">
                    <div class="grids3-info position-relative">
                        <a href="#" class="d-block zoom"><img src="../assets/images/companyfriend.jpeg" alt="" class="img-fluid news-image part1-imgs"></a>
                        <div class="w3-grids3-info light">
                            <h6><a href="#category" class="category d-block">03</a></h6>
                            <h4 class="gdnhy-1"><a href="#">质量控制</a>
                                <a class="w3item-link" href="#">
                                    <i class="fas fa-arrow-right ms-lg-4"></i>
                                </a>
                            </h4>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
    <section class="w3l-blog">
        <div class="bloghome py-5" id="Newsblog">
            <div class="container py-lg-5 py-md-4 py-2">
                <div class="row">
                  <div class="col-lg-5 title-content text-left ps-lg-5 mt-lg-0 mt-5 know2-box">
                    <h6 class="title-subw3hny mb-1">&nbsp;</h6>
                    <h3 class="title-w3l mb-3">智慧园区管理系统</h3>

                    <ul class="icon-list-items mt-3">
                        <li class="icon-list-item">
                            <i aria-hidden="true" class="fas fa-check"></i>
                            一体化智能化公共数据平台，进一步提升平台数据归集能力、数字资源统筹管理能力、
                            在基础设施支撑和安全保障能力等基础上，推动公共数据平台向智能化智慧化迈进。
                        </li>
                        <li class="icon-list-item mt-2">
                            <i aria-hidden="true" class="fas fa-check"></i>
                            即党建统领整体智治、数字政府、数字经济、数字社会、数字文化、数字法治六大系统，
                            从全面深化改革的角度，这6大系统对应全面深化改革7大领域。
                        </li>
                    </ul>
                    <router-link class="knowmore know2" to="/productYuan">
                        <span>了解详情 </span>
                        <i class="fas fa-angle-double-right"></i>
                    </router-link>
                </div>
                <div class="col-lg-7 stats_info-images pe-lg-5">
                    <div class="stats_info-images-right row project1">
                        <div class="col-6 mid-slider-content project1-box project1-pic1">
                            <img src="../assets/images/pro1_dap.png" class="img-fluid radius-image" alt="">
                        </div>
                        <div class="col-6 mid-slider-content project1-box project1-pic2" >
                            <img src="../assets/images/project1-3.png" class="img-fluid radius-image" alt="">
                        </div>
                        <div class="col-6 mid-slider-content project1-box project1-pic3" >
                            <img src="../assets/images/project1-2.png" class="img-fluid radius-image" alt="">
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </div>
    </section>
    <section class="w3l-midslider py-5" id="midslider">
        <div class="container py-md-5">
            <div class="row">
                    <div class="col-lg-5 title-content text-left ps-lg-5 mt-lg-0 mt-5 know2-box">
                        <h6 class="title-subw3hny mb-1">&nbsp;</h6>
                        <h3 class="title-w3l mb-3">产业大脑</h3>
                        <ul class="icon-list-items mt-3">
                            <li class="icon-list-item">
                                <i aria-hidden="true" class="fas fa-check"></i>
                                以数据资源和数字技术为基础，以对产业进行数字化分析为手段，
                                通过对产业发展进行智能化调节。
                            </li>
                            <li class="icon-list-item">
                                <i aria-hidden="true" class="fas fa-check"></i>
                                将资源要素数据、产业链数据、创新链数据、供应链数据等汇聚起来，
                                实现产业链和创新链双向融合，推动产业经济高质量发展。
                            </li>
                        </ul>
                        <router-link class="knowmore know3" to="/productNao">
                            <span>了解详情 </span>
                            <i class="fas fa-angle-double-right"></i>
                        </router-link>
                    </div>
                    <div class="col-lg-7 stats_info-images pe-lg-5">
                        <div class="stats_info-images-right row project2">
                            <div class="col-6 mid-slider-content project2-box project2-pic1">
                                <img src="../assets/images/brainpic.png" class="img-fluid radius-image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </section>

    <footer class="w3l-footer-29-main">
        <div class="footer-29 ">
            <div class="container" style="padding:1px">
                <div class="row footer-top-29 mt-4" style="margin:0">
                    <div class="col-lg-3 col-md-6 footer-list-29 footer-1">
                        <h6 class="footer-title-29" style="font-size:16px">地址</h6>
                        <div class="footer-listw3-grids" style="font-size:14px">
                            <ul class="footer-listw3">
                                <li>浙江省杭州市滨江区</li>
                                <li>西兴街道聚园路8号2幢主楼6层625室</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5 pe-lg-5">
                        <h6 class="footer-title-29" style="font-size:16px">联系我们</h6>
                        <div class="footer-listw3-grids" >
                            <ul class="footer-listw3">
                                <li><a style="font-size:14px;">15968875598</a></li>
                                <li><a class="mail" style="font-size:14px">15968875598@139.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5 pe-lg-5">
                        <h6 class="footer-title-29" style="font-size:16px">链接</h6>
                        <div class="footer-listw3-grids">
                            <ul class="footer-listw3" >
                                <li @click="topFunction" style="margin-bottom:0">
                                  <router-link class=" " to="/"  style="font-size:14px">首页</router-link>
                                </li>
                                <li style="margin-bottom:0">
                                  <router-link class=" " to="/productYuan" style="font-size:14px">产品</router-link>
                                </li>
                                <li style="margin-bottom:0">
                                  <router-link class=" " to="/about" style="font-size:14px">关于我们</router-link>
                                  </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-list-29 footer-3 mt-lg-0 " style="flex-grow:1">
                        <h6 class="footer-title-29" style="font-size:16px">合作伙伴</h6>
                        <div class="footer-listw3-grids flexrow">
                          <a class="comment-img" id="" href="https://www.migu.cn/">
                              <img src="../assets/images/migu.jpeg"
                              class="img-fluid radius-image " alt="">
                          </a>
                          <a class="comment-img" id="" href="https://www.wasu.com.cn/">
                              <img src="../assets/images/huashu.png"
                              class="img-fluid radius-image " alt="">
                          </a>
                          <a class="comment-img" id="" href="http://www.10086.cn/index/zj/index_571_571.html">
                              <img src="../assets/images/yidong.png"
                              class="img-fluid radius-image " alt="">
                          </a>
                          <a class="comment-img" id="" href="https://www.inspur.com/">
                              <img src="../assets/images/langchao.png"
                              class="img-fluid radius-image " alt="" style="">
                          </a>
                        </div>
                    </div>
                </div>
                <div class=" text-center">
                    <p class="copy-footer-29">© 2023 www.fansheng.vip | 备案号：<a href="https://beian.miit.gov.cn/" class="link_to" target="_blank">浙ICP备2022035524号-1</a></p>
                </div>
            </div>
        </div>
        <button @click="topFunction" id="movetop" title="回顶部">
            <span class="fa fa-angle-up"></span>
        </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ComTest',
  components: {},
  data () {
    return {

    }
  },
  methods: {
    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
  },
  created () {
    window.onscroll = function() {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("movetop").style.display = "block";
        } else {
            document.getElementById("movetop").style.display = "none";
        }
    }
    window.addEventListener("scroll", function() {
        var scroll = document.documentElement.scrollTop
        if (scroll >= 320) {
            document.getElementById('site-header').classList.add("nav-fixed");
        } else {
            document.getElementById('site-header').classList.remove("nav-fixed");
        }
    });
  }
}
</script>
<style lang="less" scoped>
@import url('../assets/css/common.css');
.navbar-nav{
    text-align: right;
}
#navbarScroll{
    .navbar-nav{
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
        .nav-item{
            // border: 1px solid blue;
            padding: 0 20px;
        }
    }
}
.companyCon{
    // font-style: italic;
    text-indent: 32px;
}
.knowmore{
    margin: 5px;
    font-size: 14px;
    color: rgb(120, 123, 123);
}
.knowmore:hover{
    cursor: pointer;
    color: rgb(167, 234, 212);
}
.part1-imgs{
    width: 100%;
    height: 150px;
}
.light{
    background-color: rgb(0 0 0 / 10%);
}
.mid-size{
    font-size: 17px;
}
.project1{
    position: relative;
}
.project1-box{
    width: 75%;
}
.project1-pic2{
    position: absolute;
    top: 60px;
    left: 60px;
    z-index: 2;
}
.project1-pic3{
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 3;
}
.project2-pic1{
    width: 100%;
}
.know2-box{
    position: relative;
}
.know2{
    position: absolute;
    left: 43px;
    top: 320px;
}
.know3{
    position: absolute;
    left: 43px;
    top: 268px;
}
#link-bg{
    // background-color: #fff;
    border: 4px solid #fdfdfd;

}
.link-img1{
    margin: 10px auto;
}
.link-img3{
    margin: 17px auto;
}
.link-img4{

}
.yewufanwei{
  justify-content: center;
}

// -header --
.pro-view{
  position: relative;
}
.products:hover .view1{
  display: block;
}
.view1{
  display: none;
  position: absolute;
  height: 200px;
  top: 72px;
  left: 20px;
  right: -100px;
  background-color: rgb(233, 233, 233);
  opacity: 0.8;
  overflow: hidden;
}
.view1 .pro_links{
  text-align: left;
  color: rgb(12, 14, 17);
  padding: 0 10px;
  font-size: 16px;
}
.view1 p{
  text-align: left;
  margin: 10px 0;
}
.view1 p:hover{
  background-color: rgb(182, 238, 255);
  transform: scale(1.1);
}
.view1 p:hover .pro_links{
  color: white;
}

//////////
.flexrow{
  display: flex;
  flex-wrap: wrap;

  .comment-img{
    width: 48%;
    margin: 5px 0px;
    flex: 0 0 40%;
    img{
        width: 80px;
        height: 40px;
        margin: 5px 0;
    }
  }

}
.link_to{
    color: #707376;
}

</style>
