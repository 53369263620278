import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/productYuan',
    name: 'productYuan',
    component: () => import(/* webpackChunkName: "product1" */ '../views/ProductYuan.vue')
  },
  {
    path: '/productNao',
    name: 'productNao',
    component: () => import(/* webpackChunkName: "product2" */ '../views/ProductNao.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
  {
    path: '/productSan',
    name: 'productSan',
    component: () => import(/* webpackChunkName: "product4" */ '../views/ProductSan.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
